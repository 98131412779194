import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ApiService from "@/common/API.service";
import { ModalPlugin } from "bootstrap-vue";
import VueQuillEditor from "vue-quill-editor";

// css
import "@/assets/css/tailwind.scss";
import "sweetalert2/dist/sweetalert2.min.css";

// 3td party
import VueSweetalert2 from "vue-sweetalert2";
import VueCrontab from "vue-crontab";
import ShortKey from "vue-shortkey";

Vue.use(ModalPlugin);
Vue.use(VueCrontab);
Vue.use(VueSweetalert2);
Vue.use(ShortKey);
Vue.use(VueQuillEditor);

Vue.config.productionTip = false;

ApiService.init();

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
