<template>
  <div id="app">
    <template v-if="cookieAllowed">
      <router-view />
    </template>
  </div>
</template>

<script>
import { lsConfig } from "@/common/mixins/mix_localStorage";
import { alert } from "@/common/mixins/mix_helper";

export default {
  name: "rwMessenger",
  mixins: [lsConfig, alert],
  data() {
    return {
      cookieAllowed: false,
    };
  },
  mounted() {
    /**
     * --------------------------------------------------------------
     * cek apakah ada index cookie enabled
     * jika ada maka update data (ntah true/false)
     * jika enabled maka request init configurasi
     * --------------------------------------------------------------
     */
    if (typeof window.navigator.cookieEnabled !== "undefined") {
      this.cookieAllowed = window.navigator.cookieEnabled;
      if (window.navigator.cookieEnabled) {
        this.mixConfigInit();
      } else {
        let message = `<div style="text-align: left">Sistem kami menggunakan cookies untuk memberikan support yang lebih efisien kepada pelanggan. <br><br> Pastikan Anda mengijinkan <b>${window.location.origin}</b> untuk membuat cookies di browser Anda.</div>`;
        this.mixSwalBlock(message, "Warning!", "warning");
      }
    }
  },
};
</script>

<style>
#app {
  font-family: sans-serif;
  width: 100%;
  height: 100%;
  overflow: hidden;
  color: #555555;
}
img.emoji {
  width: 18px;
}
.rw-spin {
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
}
@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

body::-webkit-scrollbar {
  width: 5px;
}

body::-webkit-scrollbar-thumb {
  border-left: 0;
  border-right: 0;
  background-color: #16b5ec;
}
body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
  height: 100% !important;
}
</style>
