/**
 * --------------------------------------------------------------
 * for manipulate cookies
 * --------------------------------------------------------------
 */
import { CookiesStaff, CookiesClient } from "@/common/config";
import Cookies from "js-cookie";
import { rwEncrypt, rwDecrypt } from "@/common/mixins/mix_localStorage";

/**
 * --------------------------------------------------------------
 * for customer cookie default 30day
 * isOldBrowser : for indicator browser old or not support create cookies with samesite=none
 * --------------------------------------------------------------
 */
export const clientCookies = {
  methods: {
    cookiesAdd: function (data, expired = 30) {
      let clientCookies = this.cookiesGet();
      if (typeof clientCookies !== "undefined") {
        data = Object.assign(clientCookies, data); // append object
      }
      data["isOldBrowser"] = false;
      data = rwEncrypt(data);

      /**
       * --------------------------------------------------------------
       * this is cookies for modern browser support
       * --------------------------------------------------------------
       */
      Cookies.set(CookiesClient, data, {
        expires: expired,
        secure: true,
        sameSite: "none",
      });

      /**
       * --------------------------------------------------------------
       * for cookies if not support cookies above
       * --------------------------------------------------------------
       */
      let get = this.cookiesGet();
      if (typeof get === "undefined") {
        let oldBrowser = rwDecrypt(data);
        oldBrowser["isOldBrowser"] = true;
        Cookies.set(CookiesClient, rwEncrypt(oldBrowser), { expires: expired });
        return oldBrowser;
      }
      return data;
    },

    /**
     * --------------------------------------------------------------
     * remove cookies customer
     * --------------------------------------------------------------
     */
    cookiesRemove: function () {
      Cookies.remove(CookiesClient, { path: "" });
    },

    /**
     * --------------------------------------------------------------
     * get cookies customer yang telah di decrypt
     * --------------------------------------------------------------
     */
    cookiesGet: function (index) {
      try {
        let roti = Cookies.get(CookiesClient);
        if (typeof roti !== "undefined") {
          try {
            roti = rwDecrypt(roti);
            if (this.checkField(roti)) {
              return index && typeof roti[index] !== "undefined"
                ? roti[index]
                : roti;
            } else {
              return false;
            }
          } catch (e) {
            return false;
          }
        }
      } catch (e) {
        return false;
      }
    },

    /**
     * --------------------------------------------------------------
     * minimum field yang harus ada
     * --------------------------------------------------------------
     */
    checkField: function (cookies) {
      return (
        typeof cookies.customer !== "undefined" &&
        typeof cookies.customer.id !== "undefined" &&
        typeof cookies.customer.name !== "undefined" &&
        typeof cookies.customer.email !== "undefined" &&
        typeof cookies.isLogin !== "undefined" &&
        typeof cookies.threadid !== "undefined"
      );
    },

    /**
     * --------------------------------------------------
     * sync with vuex
     * --------------------------------------------------
     */
    cookiesSyncUpdate: async function (configParam) {
      await this.$store.dispatch("ConfigJs", configParam);
      this.cookiesAdd(configParam);
    },
  },
};

/**
 * --------------------------------------------------------------
 * for staff only cookies default expired 1day
 * --------------------------------------------------------------
 */
export const staffCookies = {
  data() {
    return {
      cookies: {
        /**
         * --------------------------------------------------------------
         * menambahkan / append cookies staff
         * --------------------------------------------------------------
         */
        add: function (data = Object, expired = 1) {
          let rwCookies = Cookies.get(CookiesStaff);
          if (typeof rwCookies !== "undefined") {
            try {
              rwCookies = JSON.parse(rwCookies);
              data = Object.assign(rwCookies, data); // append object
            } catch (e) {
              this.remove();
              return;
            }
          }
          try {
            Cookies.set(CookiesStaff, JSON.stringify(data), {
              expires: expired,
              secure: true,
              sameSite: "none",
            });
          } catch (e) {
            this.remove();
          }
        },
        get: function (index = false) {
          let rwCookies = Cookies.get(CookiesStaff);
          if (typeof rwCookies !== "undefined") {
            try {
              rwCookies = JSON.parse(rwCookies);
              return index && typeof rwCookies[index] !== "undefined"
                ? rwCookies[index]
                : rwCookies;
            } catch (e) {
              this.remove();
            }
          }
          return false;
        },
        remove: function () {
          Cookies.remove(CookiesStaff, { path: "" });
        },
        /**
         * --------------------------------------------------------------
         * compare agentid param with agentid cookies
         * @return boolean
         * --------------------------------------------------------------
         */
        sameWithAgentCurrent: function (agentid) {
          try {
            let cookies = this.get();
            if (
              typeof cookies !== "undefined" &&
              typeof cookies.staff !== "undefined" &&
              typeof cookies.staff.id !== "undefined"
            ) {
              let castingAgentParam = parseInt(agentid);
              let castingAgentCookies = parseInt(cookies.staff.id);
              return castingAgentParam === castingAgentCookies;
            } else {
              return false;
            }
          } catch (e) {
            return false;
          }
        },
      },
    };
  },
};
